import { Injectable } from '@angular/core';

import { User } from '@auth0/auth0-spa-js';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';
import { first } from 'rxjs/operators';

import { PermissionsService } from '@zonar-ui/auth';

import { environment } from '@environments/environment';
import { appName, appVersion } from '@environments/shared';
import { isLocalDevEnv } from '@src/app/shared/utilities/utilities';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {
  private user: User;
  env = environment;
  constructor(private permissions: PermissionsService) {
    if (!isLocalDevEnv()) {
      // enable real user monitoring for all non-local (deployed) environments
      datadogRum.init({
        applicationId: this.env.datadog.applicationId,
        clientToken: this.env.datadog.clientToken,
        site: this.env.datadog.site,
        env: this.env.datadog.tagEnvironment,
        service: appName,
        version: appVersion,
        sampleRate: 100,
        sessionSampleRate: 100,
        premiumSampleRate: 100,
        trackUserInteractions: true,
        replaySampleRate: 100,
        trackInteractions: true,
        allowedTracingOrigins: [
          /https:\/\/.*\.zonarsystems\.net/,
          environment.apiBase.url
        ],
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      datadogLogs.init({
        clientToken: this.env.datadog.clientToken,
        env: this.env.datadog.tagEnvironment,
        service: appName,
        site: this.env.datadog.site,
        forwardErrorsToLogs: false,
        sampleRate: 100
      });

      this.permissions
        .getUsers()
        .pipe(first((users) => Boolean(users?.length)))
        .subscribe((user) => {
          this.user = user[0];
          datadogRum.setUser({
            id: this.user.id,
            email: this.user.email,
            sub: this.user.sub
          });
        });
    }
  }

  addRumAction(name: string, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addAction(name, context);
    }
  }

  addRumError(error: Error, context?: object | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addError(error, context);
    }
  }

  addRumTiming(name: string, time?: number | undefined): void {
    if (!isLocalDevEnv()) {
      datadogRum.addTiming(name, time || Date.now());
    }
  }

  getRumUser(): User | undefined {
    return this.user;
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    if (!isLocalDevEnv()) {
      datadogLogs.logger.log(message, attributes, status);
    }
  }
}
