import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, Routes, RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';

import { Observable, Subject } from 'rxjs';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';

// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewContainerComponent implements OnInit, OnDestroy {
  public cardData;
  public columns;
  public filterList;
  public pageTitle: string;
  public navLinks = [];

  private onDestroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  ngOnInit(): void {
    this.navLinks =
      !!this.route.routeConfig && !!this.route.routeConfig.children.length
        ? this.buildNavigatingItems(this.route.routeConfig.children)
        : [];
  }

  buildNavigatingItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data.label
      }));
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
